import _ from 'lodash';
import React, { Component } from 'react';

import { Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import font from './fonts/tahoma.ttf';
import fontBold from './fonts/tahomabd.ttf';

import logo from './../../Global/croner_logo.png';

export default class BasicQueryPrint extends Component {
  getCurrency(country) {
    var currency = '';

    if (country === 'Canada') {
      currency = 'CAD ';
    } else if (country === 'China') {
      currency = 'CNY ';
    } else if (
      country === 'France' ||
      country === 'Germany' ||
      country === 'Spain' ||
      country === 'Finland' ||
      country === 'Italy'
    ) {
      currency = 'EUR ';
    } else if (country === 'United Kingdom') {
      currency = 'GBP ';
    } else if (country === 'Australia') {
      currency = 'AUD ';
    } else if (country === 'India') {
      currency = 'INR ';
    } else if (country === 'Singapore') {
      currency = 'SGD ';
    } else if (country === 'South Korea') {
      currency = 'KRW ';
    } else if (country === 'Sweden') {
      currency = 'SEK ';
    } else if (country === 'Japan') {
      currency = 'JPY ';
    } else if (country === 'Poland') {
      currency = 'PLN ';
    } else if (country === 'Brazil') {
      currency = 'BRL ';
    } else if (country === 'Mexico') {
      currency = 'MXN ';
    } else {
      currency = '$USD ';
    }

    return '(in ' + currency + 'Thousands)';
  }

  // render the cut sections
  renderCutSections(
    queryData,
    percentiles,
    hourly,
    cuts,
    styles,
    currentCut,
    incCol,
    msoSales,
    byPos
  ) {
    let i = 0;
    return _.map(_.pickBy(cuts, _.identity), (c) => {
      i++;
      let cutResults = _.filter(queryData, { sortorder: c });

      // for MSO determine if the position is executive
      if (queryData[0].surveycode.includes('M_')) {
        var lastTwoDigits = queryData[0].finalcode.toString().slice(-2);
        var msoExec = parseInt(lastTwoDigits) <= 12 ? true : false;
      }
      let currency = '';
      // for incrementing cut number
      return _.map(cutResults, (data) => {
        if (data.country) {
          if (data.country === 'United Kingdom') {
            currency = '£';
          } else if (data.country === 'China') {
            currency = '¥';
          } else if (data.country === 'Australia') {
            currency = '$';
          } else if (data.country === 'India') {
            currency = '₹';
          } else if (data.country === 'Singapore') {
            currency = '$';
          } else if (data.country === 'South Korea') {
            currency = '₩';
          } else if (data.country === 'Sweden') {
            currency = ' kr';
          } else if (data.country === 'Japan') {
            currency = '¥';
          } else if (data.country === 'Brazil') {
            currency = 'R$ ';
          } else if (data.country === 'Poland') {
            currency = ' zł';
          } else if (
            data.country === 'Spain' ||
            data.country === 'Germany' ||
            data.country === 'France' ||
            data.country === 'Finland' ||
            data.country === 'Italy'
          ) {
            currency = '€';
          } else {
            currency = '$';
          }
        } else {
          currency = '$';
        }

        // Adjust page brakes view the break attribute below
        return (
          <View
            break={
              !data.surveycode.startsWith('F_')
                ? !byPos &&
                  data.surveycode.startsWith('M_') &&
                  data.finalcode < 13 &&
                  (i === 3 || (i > 3 && i % 2 === 1))
                  ? true
                  : !byPos &&
                    data.surveycode.startsWith('M_') &&
                    data.finalcode >= 13 &&
                    data.finalcode <= 26 &&
                    (i === 4 || (i > 4 && i % 3 === 1))
                  ? true
                  : !byPos &&
                    data.surveycode.startsWith('M_') &&
                    data.finalcode >= 27 &&
                    data.finalcode <= 40 &&
                    (i === 8 || (i > 8 && i % 7 === 1))
                  ? true
                  : !byPos &&
                    data.surveycode.startsWith('L_') &&
                    (i === 5 || (i > 5 && i % 4 === 1))
                  ? true
                  : !byPos &&
                    !data.surveycode.startsWith('M_') &&
                    !data.surveycode.startsWith('L_') &&
                    (i === 4 || (i > 4 && i % 3 === 1))
                  ? true
                  : byPos && msoExec && (i === 3 || (i > 3 && i % 2 === 1))
                  ? true
                  : byPos && hourly && (i === 7 || (i > 7 && i % 7 === 0))
                  ? true
                  : byPos && msoSales && (i === 7 || (i > 8 && i % 8 === 0))
                  ? true
                  : byPos &&
                    !hourly &&
                    !msoSales &&
                    (i === 3 || (i > 3 && i % 3 === 0))
                  ? true
                  : false
                : i === 7 || (i > 7 && i % 6 === 1)
                ? true
                : false
            }
            key={i}
          >
            {/* SECTION HEADER */}
            {this.renderSectionTitle(data, styles)}

            {/* BASE */}
            {this.renderDataRow(
              data,
              percentiles,
              styles,
              'Base Salary',
              'base',
              hourly ? 1 : 1000,
              currency,
              hourly ? 2 : 1,
              data.surveycode === 'A_CAN_19' ||
                data.surveycode === 'A_CAN_21' ||
                data.surveycode === 'E_INTL_22'
                ? true
                : false,
              incCol,
              'odd'
            )}
            {data.surveycode !== 'A_CAN_19' &&
            data.surveycode !== 'A_CAN_21' &&
            data.surveycode !== 'E_INTL_22'
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Paired Comparison Base Salary',
                  'paired',
                  100,
                  '%',
                  1,
                  !data.surveycode.startsWith('A_') ||
                    data.surveycode === 'A_CAN_22' ||
                    data.surveycode === 'A_CAN_23' ||
                    data.surveycode === 'A_CAN_24'
                    ? true
                    : false,
                  incCol,
                  'even'
                )
              : null}
            {data.surveycode.startsWith('A_') &&
            !data.surveycode.includes('_CAN_')
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Estimated Weekly OT Hours',
                  'stdhrs',
                  1,
                  '',
                  1,
                  false,
                  incCol,
                  'odd'
                )
              : null}
            {data.surveycode.startsWith('A_') &&
            !data.surveycode.includes('_CAN_')
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Base Salary + Annual OT',
                  'baseinclot',
                  1000,
                  currency,
                  1,
                  true,
                  incCol,
                  'even'
                )
              : null}

            {/* STI */}
            {!data.surveycode.startsWith('F_')
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Target Total Cash Compensation',
                  'tgttcc',
                  hourly ? 1 : 1000,
                  currency,
                  hourly ? 2 : 1,
                  false,
                  incCol,
                  'odd'
                )
              : null}
            {data.surveycode.startsWith('F_')
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Cash Incentives / Bonuses',
                  'addlcash',
                  1000,
                  currency,
                  1,
                  false,
                  incCol,
                  'odd'
                )
              : null}
            {this.renderDataRow(
              data,
              percentiles,
              styles,
              data.surveycode.startsWith('F_')
                ? 'Annual Total Cash'
                : 'Actual Total Cash Compensation',
              'tcc',
              hourly ? 1 : 1000,
              currency,
              hourly ? 2 : 1,
              false,
              incCol,
              'even'
            )}
            {(data.surveycode !== 'A_CAN_19') &
            (data.surveycode !== 'A_CAN_21') &
            (data.surveycode !== 'E_INTL_22')
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  data.surveycode.startsWith('F_')
                    ? 'Paired Comparison Annual Total Cash Compensation'
                    : 'Paired Comparison Actual Total Cash Compensation',
                  'pairedtcc',
                  100,
                  '%',
                  1,
                  data.surveycode.startsWith('F_') ||
                    (msoSales && !data.rptcut.includes('All Incumbents'))
                    ? true
                    : false,
                  incCol,
                  'odd'
                )
              : null}
            {data.surveycode.startsWith('F_')
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Estimated Total Compensation',
                  'esttcc',
                  1000,
                  currency,
                  1,
                  false,
                  incCol,
                  'even'
                )
              : null}
            {data.surveycode.startsWith('A_') &&
            !data.surveycode.includes('_CAN_')
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Actual Total Cash Compensation + Annual OT',
                  'tccinclot',
                  1000,
                  currency,
                  1,
                  false,
                  incCol,
                  'even'
                )
              : null}
            {!data.surveycode.startsWith('F_')
              ? msoSales && !data.rptcut.includes('All Incumbents')
                ? null
                : this.renderDataRow(
                    data,
                    percentiles,
                    styles,
                    'Actual Cash Awards as % of Base Salary',
                    'pctaddl',
                    100,
                    '%',
                    1,
                    true,
                    incCol,
                    data.surveycode.startsWith('A_') ? 'odd' : 'even'
                  )
              : null}

            {/* LTI */}
            {!data.surveycode.startsWith('F_') &&
            !hourly &&
            !(msoSales && !data.rptcut.includes('All Incumbents'))
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Annualized Target LTI Awards',
                  'ltitgtvalue',
                  1000,
                  currency,
                  1,
                  false,
                  incCol,
                  'odd'
                )
              : null}
            {!data.surveycode.startsWith('F_') &&
            !hourly &&
            !(msoSales && !data.rptcut.includes('All Incumbents'))
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Annualized Value of LTI Awards',
                  'ltaddl',
                  1000,
                  currency,
                  1,
                  false,
                  incCol,
                  'even'
                )
              : null}
            {!data.surveycode.startsWith('F_') &&
            !data.surveycode.startsWith('A_') &&
            !data.surveycode.startsWith('D_16') &&
            !data.surveycode.startsWith('_15') &&
            !hourly &&
            !(msoSales && !data.rptcut.includes('All Incumbents'))
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Target Total Direct Compensation',
                  'tgttdc',
                  1000,
                  currency,
                  1,
                  false,
                  incCol,
                  'odd'
                )
              : null}
            {!data.surveycode.startsWith('F_') &&
            !hourly &&
            !(msoSales && !data.rptcut.includes('All Incumbents'))
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Actual Total Direct Compensation',
                  'tdc',
                  1000,
                  currency,
                  1,
                  data.surveycode === 'A_CAN_19' ||
                    data.surveycode === 'A_CAN_21'
                    ? true
                    : false,
                  incCol,
                  'even'
                )
              : null}
            {data.surveycode.startsWith('F_')
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Paired Comparison Est. Total Direct Compensation',
                  'pairedesttcc',
                  100,
                  '%',
                  1,
                  true,
                  incCol,
                  'odd'
                )
              : null}
            {data.surveycode !== 'A_CAN_19' &&
            data.surveycode !== 'A_CAN_21' &&
            data.surveycode !== 'E_INTL_22' &&
            !data.surveycode.startsWith('F_') &&
            !hourly &&
            !(msoSales && !data.rptcut.includes('All Incumbents'))
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Paired Comparison Actual Total Direct Compensation',
                  'pairedtdc',
                  100,
                  '%',
                  1,
                  msoSales || data.surveycode.startsWith('A_CAN_')
                    ? true
                    : false,
                  incCol,
                  'odd'
                )
              : null}
            {data.surveycode.startsWith('A_') &&
            !data.surveycode.includes('_CAN_')
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Actual Total Direct Compensation + Annual OT',
                  'tdcinclot',
                  1000,
                  currency,
                  1,
                  true,
                  incCol,
                  'even'
                )
              : null}
            {!data.surveycode.startsWith('F_') &&
            !data.surveycode.startsWith('A_') &&
            !data.surveycode.startsWith('F_') &&
            !hourly &&
            !msoSales
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'LTI as % of Base Salary',
                  'ltipctbase',
                  100,
                  '%',
                  1,
                  true,
                  incCol,
                  'even'
                )
              : null}

            {/* LTI - ONGOING */}
            {!data.surveycode.startsWith('F_') &&
            !data.surveycode.startsWith('A_') &&
            !data.surveycode.startsWith('L_') &&
            !hourly &&
            !(msoSales && !data.rptcut.includes('All Incumbents'))
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Annualized Value of LTI Awards - Ongoing',
                  'ltaddlong',
                  1000,
                  currency,
                  1,
                  false,
                  incCol,
                  msoSales ? 'even' : 'odd'
                )
              : null}
            {!data.surveycode.startsWith('F_') &&
            !data.surveycode.startsWith('A_') &&
            !data.surveycode.startsWith('L_') &&
            !hourly &&
            !(msoSales && !data.rptcut.includes('All Incumbents'))
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Actual Total Direct Compensation - Ongoing',
                  'tdcong',
                  1000,
                  currency,
                  1,
                  msoSales ? true : false,
                  incCol,
                  msoSales ? 'odd' : 'even'
                )
              : null}
            {!data.surveycode.startsWith('F_') &&
            !data.surveycode.startsWith('A_') &&
            !data.surveycode.startsWith('L_') &&
            !hourly &&
            !msoSales
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'LTI as % of Base Salary - Ongoing',
                  'ltipctbaseong',
                  100,
                  '%',
                  1,
                  true,
                  incCol,
                  'odd'
                )
              : null}

            {/* SURVEY SPECIFIC SECTIONS */}
            {/* DCT */}
            {data.surveycode.startsWith('D_') &&
            (data.finalcode === 36 ||
              (data.finalcode >= 80010.5 && data.finalcode <= 82528))
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Annual Revenue Team Target (in $MM)',
                  'revtgtteamcfy',
                  1000000,
                  currency,
                  1,
                  false,
                  incCol,
                  'even'
                )
              : data.surveycode.startsWith('D_')
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Digital Revenue (in $MM)',
                  'digitalrev',
                  1000000,
                  currency,
                  1,
                  true,
                  incCol,
                  'even'
                )
              : null}
            {data.surveycode.startsWith('D_') &&
            (data.finalcode === 36 ||
              (data.finalcode >= 80010.5 && data.finalcode <= 82528))
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Annual Revenue Indiv Target (in $MM)',
                  'revtgtindvcfy',
                  1000000,
                  currency,
                  1,
                  true,
                  incCol,
                  'odd'
                )
              : null}

            {/* ENT */}
            {data.surveycode.startsWith('E_') &&
            ((data.finalcode >= 130000 && data.finalcode <= 130028) ||
              (data.finalcode >= 230000 && data.finalcode <= 234025) ||
              (data.finalcode >= 430000 && data.finalcode <= 433516) ||
              (data.finalcode >= 530000 && data.finalcode <= 530016) ||
              (data.finalcode >= 30010 && data.finalcode <= 33528) ||
              data.finalcode === 3005 ||
              data.finalcode === 1300500 ||
              data.finalcode === 2300500 ||
              data.finalcode === 5300500)
              ? [
                  this.renderDataRow(
                    data,
                    percentiles,
                    styles,
                    'Annual Revenue Team Target (in $MM)',
                    'revtgtteamcfy',
                    1000000,
                    currency,
                    0,
                    false,
                    incCol,
                    'even'
                  ),
                  this.renderDataRow(
                    data,
                    percentiles,
                    styles,
                    'Annual Revenue Indiv Target (in $MM)',
                    'revtgtindvcfy',
                    1000000,
                    currency,
                    0,
                    true,
                    incCol,
                    'odd'
                  ),
                ]
              : null}

            {/* LMS 2022*/}
            {(data.surveycode === 'L_22' || data.surveycode === 'L_23') &&
            data.finalcode < 13000
              ? [
                  this.renderDataRow(
                    data,
                    percentiles,
                    styles,
                    'Annual Revenue Target (in $MM)',
                    'revactlfy',
                    1000000,
                    currency,
                    1,
                    false,
                    incCol,
                    'odd'
                  ),
                  this.renderDataRow(
                    data,
                    percentiles,
                    styles,
                    'Target Total Cash Compensation as % of Target Revenue',
                    'tgttccpcttgtrev',
                    100,
                    '%',
                    1,
                    true,
                    incCol,
                    'even'
                  ),
                ]
              : null}

            {/* LMS 2019-2021*/}
            {(data.surveycode === 'L_19' ||
              data.surveycode === 'L_20' ||
              data.surveycode === 'L_21') &&
            data.finalcode <= 15027
              ? [
                  this.renderDataRow(
                    data,
                    percentiles,
                    styles,
                    'Annual Revenue Target (in $MM)',
                    'revactlfy',
                    1000000,
                    currency,
                    1,
                    false,
                    incCol,
                    'odd'
                  ),
                  this.renderDataRow(
                    data,
                    percentiles,
                    styles,
                    'Target Total Cash Compensation as % of Target Revenue',
                    'tgttccpcttgtrev',
                    100,
                    '%',
                    1,
                    true,
                    incCol,
                    'even'
                  ),
                ]
              : null}
            {/* MSO */}
            {data.surveycode.startsWith('M_') &&
            !msoSales &&
            ((data.finalcode >= 11 && data.finalcode <= 12) ||
              Number(data.finalcode.toString().slice(-2)) <= 12)
              ? [
                  this.renderDataRow(
                    data,
                    percentiles,
                    styles,
                    data.rptcut.includes('Field') || data.surveycode === 'M_15'
                      ? 'Field Revenues (in $MM)'
                      : 'Corporate Revenues (in $MM)',
                    data.rptcut.includes('Field') || data.surveycode === 'M_15'
                      ? 'fieldrev'
                      : 'corprev',
                    1000000,
                    currency,
                    1,
                    false,
                    incCol,
                    'even'
                  ),
                  this.renderDataRow(
                    data,
                    percentiles,
                    styles,
                    data.rptcut.includes('Field') || data.surveycode === 'M_15'
                      ? 'Field Subscribers'
                      : 'Corporate Subscribers',
                    data.rptcut.includes('Field') || data.surveycode === 'M_15'
                      ? 'fieldsub'
                      : 'corpsub',
                    1000,
                    '',
                    0,
                    false,
                    incCol,
                    'odd'
                  ),
                  this.renderDataRow(
                    data,
                    percentiles,
                    styles,
                    data.rptcut.includes('Field') || data.surveycode === 'M_15'
                      ? 'Field Revenue Generating Units (RGUs)'
                      : 'Corporate Revenue Generating Units (RGUs)',
                    data.rptcut.includes('Field') || data.surveycode === 'M_15'
                      ? 'fieldrgu'
                      : 'corprgu',
                    1000,
                    '',
                    0,
                    true,
                    incCol,
                    'even'
                  ),
                ]
              : null}

            {/* PRG */}
            {(data.surveycode.startsWith('P_') &&
              (data.finalcode < 70000 || data.finalcode > 76100) &&
              ((Number(data.finalcode.toString().slice(-2)) >= 10 &&
                Number(data.finalcode.toString().slice(-2)) <= 12) ||
                (data.finalcode >= 1 && data.finalcode <= 28))) ||
            (data.surveycode.startsWith('P_') &&
              data.finalcode >= 1000000 &&
              data.finalcode <= 1200000)
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Revenues (in $MM)',
                  'revlfy',
                  1000000,
                  currency,
                  1,
                  data.surveycode.startsWith('P_22') ? true : false,
                  incCol,
                  'even'
                )
              : null}

            {/* {(data.surveycode.startsWith('P_') &&
              !data.surveycode.endsWith('22') &&
              (data.finalcode < 70000 || data.finalcode > 76100) &&
              ((Number(data.finalcode.toString().slice(-2)) >= 10 &&
                Number(data.finalcode.toString().slice(-2)) <= 12) ||
                (data.finalcode >= 1 && data.finalcode <= 28))) ||
            (data.surveycode.startsWith('P_') &&
              !data.surveycode.endsWith('22') &&
              data.finalcode >= 1000000 &&
              data.finalcode <= 1200000)
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Cumulative Basic Subscribers (in MM)',
                  'ussub',
                  1000000,
                  '',
                  0,
                  true,
                  incCol,
                  'odd'
                )
              : null} */}

            {data.surveycode.startsWith('P_') &&
            data.finalcode >= 70000 &&
            data.finalcode <= 76100
              ? [
                  this.renderDataRow(
                    data,
                    percentiles,
                    styles,
                    'Annual Revenue Team Targets (in $MM)',
                    'revtgtteamcfy',
                    1000000,
                    currency,
                    1,
                    false,
                    incCol,
                    'even'
                  ),
                  this.renderDataRow(
                    data,
                    percentiles,
                    styles,
                    'Annual Revenue Indiv Targets (in $MM)',
                    'revtgtindvcfy',
                    1000000,
                    currency,
                    1,
                    true,
                    incCol,
                    'odd'
                  ),
                ]
              : null}

            {/* SWG */}
            {data.surveycode.startsWith('S_') &&
            data.country === 'United States' &&
            ((data.finalcode >= 25 && data.finalcode <= 99) ||
              (data.finalcode >= 100 && data.finalcode <= 120) ||
              Number(data.finalcode.toString().slice(-2)) <= 12)
              ? this.renderDataRow(
                  data,
                  percentiles,
                  styles,
                  'Revenues (in $MM)',
                  'revlfy',
                  1000000,
                  currency,
                  1,
                  true,
                  incCol,
                  'even'
                )
              : null}
          </View>
        );
      });
    });
  }

  // render the data rows
  renderDataRow(
    data,
    percentiles,
    styles,
    dataName,
    varName,
    divisor,
    symbol,
    digits,
    bottomBorder,
    incCol,
    rowNumber
  ) {
    // console.log(data);
    // console.log(percentiles);
    // console.log(dataName);
    // console.log(varName);
    let incColCount = 1;
    return (
      <View
        style={[
          bottomBorder ? styles.bottomBorder : '',
          rowNumber === 'even' ? styles.evenRow : '',
          styles.dataRow,
        ]}
        key={varName}
      >
        <View style={styles.dataNameCell}>
          <Text style={styles.dataNameText}>{dataName}</Text>
        </View>
        {_.map(percentiles, (v, k) => {
          let fieldStr = '';
          // construct the field name to extract the correct JSON
          if (k.startsWith('inc')) {
            // create a string for logic to be run on
            fieldStr =
              varName +
              'inc' +
              (v === 'n'
                ? 'n'
                : v === 50
                ? 'median'
                : v.toString().replace('.', '_'));
          } else if (k.startsWith('co')) {
            // create a string for logic to be run on
            fieldStr =
              varName +
              'co' +
              (v === 'n'
                ? 'n'
                : v === 50
                ? 'median'
                : v.toString().replace('.', '_'));
          }

          // determine if a left border needs to be applied to CoN by seeing if last
          // three characters are CoN
          if (incColCount === incCol) {
            var rightBorder = true;
          }

          // if Paired comparison and Inc, gray out the cell that it should never have data
          if (fieldStr.includes('paired') && fieldStr.includes('inc')) {
            var noData = true;
          }

          // determine what the N should be if it is below 5 or other things
          let nDataValue;
          if (
            (fieldStr === 'pairedtcccon' &&
              data.pairedcon < data.pairedtcccon &&
              data.pairedtcccomean) ||
            (fieldStr === 'pairedtdccon' &&
              data.pairedcon < data.pairedtdccon &&
              data.pairedtdccomean) ||
            (fieldStr === 'pairedtcccon' &&
              data.pairedtcccon < 5 &&
              data.pairedtcccomean)
          ) {
            nDataValue = data.pairedcon;
          } else if (
            fieldStr === 'pairedtdccon' &&
            data.pairedtdccon < 5 &&
            data.pairedtdccomean
          ) {
            nDataValue = data.pairedtcccon < 5 ? 5 : data.pairedtcccon;
          }

          // increment the column counter
          incColCount++;

          // get the data value for the key
          let fieldObj = data[fieldStr];
          // console.log(fieldObj);
          let dataValue;

          // determine if the data point is a number
          if (
            !fieldStr.endsWith('incn') &&
            !fieldStr.endsWith('con') &&
            !isNaN(parseFloat(fieldObj)) &&
            isFinite(fieldObj)
          ) {
            // console.log('true 1');
            // if the number is a percentage
            if (symbol === '%') {
              // console.log('true 2');
              dataValue = (fieldObj * divisor).toLocaleString('en-US', {
                minimumFractionDigits: digits,
                maximumFractionDigits: digits,
              });
            } else {
              // handling revenues
              // console.log('true 3');
              if (
                (fieldStr.includes('corprgu') ||
                  fieldStr.includes('fieldrgu') ||
                  fieldStr.includes('corpsub') ||
                  fieldStr.includes('fieldsub') ||
                  fieldStr.includes('corprev') ||
                  fieldStr.includes('fieldrev') ||
                  fieldStr.includes('digitalrev') ||
                  fieldStr.includes('revlfy') ||
                  fieldStr.includes('ussub')) &&
                (fieldStr.includes('median') || fieldStr.includes('mean'))
              ) {
                // console.log('true 4');
                dataValue = (fieldObj / divisor).toLocaleString('en-US', {
                  minimumFractionDigits: digits,
                  maximumFractionDigits: digits,
                });
              } else if (
                fieldObj >= 0 &&
                (fieldStr.indexOf('corprgu') ||
                  fieldStr.indexOf('fieldrgu') ||
                  fieldStr.indexOf('corpsub') ||
                  fieldStr.indexOf('fieldsub') ||
                  fieldStr.indexOf('corprev') ||
                  fieldStr.indexOf('fieldrev') ||
                  fieldStr.indexOf('digitalrev') ||
                  fieldStr.indexOf('revlfy') ||
                  fieldStr.indexOf('ussub'))
              ) {
                // console.log('true 5');
                if (
                  fieldStr.includes('corprgu') ||
                  fieldStr.includes('fieldrgu') ||
                  fieldStr.includes('corpsub') ||
                  fieldStr.includes('fieldsub') ||
                  fieldStr.includes('corprev') ||
                  fieldStr.includes('fieldrev') ||
                  fieldStr.includes('digitalrev') ||
                  fieldStr.includes('revlfy') ||
                  fieldStr.includes('ussub')
                ) {
                  // console.log('true 6');
                  dataValue = '';
                } else {
                  // console.log('true 7');
                  dataValue = (fieldObj / divisor).toLocaleString('en-US', {
                    minimumFractionDigits: digits,
                    maximumFractionDigits: digits,
                  });
                }
              }
            }
          }

          // render the data points
          return (
            <View
              key={k}
              style={
                rightBorder && noData
                  ? [styles.dataPointStdCell, styles.rightBorder, styles.noData]
                  : rightBorder
                  ? [styles.dataPointStdCell, styles.rightBorder]
                  : noData
                  ? [styles.dataPointStdCell, styles.noData]
                  : styles.dataPointStdCell
              }
            >
              {fieldStr[fieldStr.length - 1] === 'n' &&
              fieldObj === null &&
              !fieldStr.includes('mean') &&
              !fieldStr.includes('median') ? (
                <Text key={k} style={styles.dataStdText}></Text>
              ) : fieldStr[fieldStr.length - 1] === 'n' &&
                fieldObj >= 0 &&
                !fieldStr.includes('mean') &&
                !fieldStr.includes('median') ? (
                <Text key={k} style={styles.dataStdText}>
                  {nDataValue ? nDataValue : fieldObj.toLocaleString()}
                </Text>
              ) : fieldObj >= 0 ? (
                <Text key={k} style={styles.dataStdText}>
                  {fieldObj === null || dataValue === undefined
                    ? null
                    : symbol === '%'
                    ? dataValue + symbol
                    : dataValue === ''
                    ? dataValue
                    : symbol === ' kr' || symbol === ' zł'
                    ? dataValue + symbol
                    : symbol + dataValue}
                </Text>
              ) : (
                <Text key={k} style={styles.dataStdText}>
                  {window.location.pathname === '/demo/query/' && fieldObj
                    ? symbol === '%'
                      ? '░.░░' + symbol
                      : fieldObj === ''
                      ? fieldObj
                      : symbol === ' kr' || symbol === ' zł'
                      ? fieldObj + symbol
                      : symbol + fieldObj
                    : null}
                </Text>
              )}
            </View>
          );
        })}
      </View>
    );
  }

  // render the percentile row
  renderPercentiles(percentiles, styles) {
    return _.map(percentiles, (v, k) => {
      return (
        <View style={styles.dataPointHeaderCell} key={k} fixed>
          <Text style={styles.centerTextSingleRowHeader} key={k} fixed>
            {Number.isInteger(v) || v === 62.5
              ? v + 'th'
              : v === 'mean'
              ? 'Mean'
              : v}
          </Text>
        </View>
      );
    });
  }

  renderSectionTitle(data, styles) {
    // logica surrounding how to concat the RptCut and RptCutcats
    let sectionTitle = '';
    if (
      data.rptcutcat === 'All Incumbents' &&
      data.rptcut === 'All Incumbents'
    ) {
      sectionTitle = 'All Incumbents';
    } else if (data.surveycode.startsWith('A_')) {
      sectionTitle = data.rptcut;
    } else {
      sectionTitle = data.rptcutcat + ' - ' + data.rptcut;
    }

    return (
      <View style={styles.sectionTitle}>
        <Text style={styles.sectionTitleText}>{sectionTitle}</Text>
      </View>
    );
  }

  render() {
    const {
      coCol,
      country,
      cuts,
      currentCut,
      dataEffective,
      hourly,
      incCol,
      msoSales,
      percentiles,
      posCode,
      posName,
      posDesc,
      queryData,
      surveyName,
      posDescHeight,
    } = this.props;

    // set the font styles and get rid of hyphenation
    Font.register(font, { family: 'tahoma' });
    Font.register(fontBold, { family: 'tahomabd' });
    Font.registerHyphenationCallback((word) => [word]);

    // variables for the styles
    // 539 is the total width of the content area
    // 1493 is the rough total height of the content area
    const roughHeight = queryData[0].surveycode.startsWith('E_') ? 1790 : 1493;
    const regFontSize = 7,
      largeFontSize = 9,
      footerFontSize = 5,
      sectionSpacing = 6,
      leftColumnCellWidth = 155,
      contentHeight = posDescHeight > roughHeight ? roughHeight * 1 : roughHeight,
      contentWidth = 539,
      headerSingleRowWidth = contentWidth - leftColumnCellWidth,
      cellWidth = headerSingleRowWidth / (incCol + coCol),
      incSectionWidth = cellWidth * incCol,
      coSectionWidth = headerSingleRowWidth - incSectionWidth,
      doubleRowHeight = 25,
      rowHeightForDoubleRow = (doubleRowHeight - 1) / 2,
      doubleRowCellPadding = (doubleRowHeight - regFontSize - 4) / 2,
      singleRowCellPadding = (rowHeightForDoubleRow - regFontSize - 2) / 2,
      descHeight =
        ((contentHeight - posDescHeight) / contentHeight) * 122 + '%',
      dataRowHeight = rowHeightForDoubleRow;

    // Declare all of the styles
    const styles = StyleSheet.create({
      body: {
        paddingLeft: 36,
        paddingRight: 36,
        paddingBottom: 36,
        paddingTop: 32,
      },
      watermark: {
        fontSize: 128,
        transform: 'rotate(-45deg)',
        height: 0,
        color: 'gray',
        position: 'absolute',
        top: '300px',
        left: '-30px',
        zIndex: 9999,
      },
      posTitle: {
        fontSize: regFontSize,
        fontFamily: 'tahomabd',
        borderBottom: '1pt solid black',
      },
      posDesc: {
        fontSize: regFontSize - 1,
        fontFamily: 'tahoma',
        marginTop: sectionSpacing,
      },
      posDescFullPage: {
        fontSize: regFontSize - 1,
        fontFamily: 'tahoma',
        marginTop: sectionSpacing,
        marginBottom: descHeight,
        height: '100%',
      },
      sectionHeading: {
        fontSize: largeFontSize,
        textAlign: 'center',
        fontFamily: 'tahomabd',
        marginTop: sectionSpacing,
      },
      doubleRow: {
        flexDirection: 'row',
        marginTop: sectionSpacing,
        height: doubleRowHeight,
        border: '1pt solid black',
        backgroundColor: '#D9D9D9',
      },
      headerStackedRows: {
        flexDirection: 'column',
      },
      singleRowInDoubleTop: {
        flexDirection: 'row',
        height: rowHeightForDoubleRow,
        borderBottom: '1pt solid black',
        width: headerSingleRowWidth,
      },
      singleRowInDoubleBottom: {
        flexDirection: 'row',
        height: rowHeightForDoubleRow,
        width: headerSingleRowWidth,
      },
      doubleRowCell: {
        width: leftColumnCellWidth,
        borderRight: '1pt solid black',
        height: '100%',
      },
      incTitleCell: {
        borderRight: '1pt solid black',
        width: incSectionWidth,
      },
      coTitleCell: {
        width: coSectionWidth,
      },
      dataPointHeaderCell: {
        width: cellWidth,
        borderRight: '1pt solid black',
      },
      dataPointStdCell: {
        width: cellWidth,
      },
      centerTextDoubleRowHeader: {
        fontSize: regFontSize,
        textAlign: 'center',
        paddingTop: doubleRowCellPadding,
        fontFamily: 'tahomabd',
      },
      centerTextSingleRowHeader: {
        fontSize: regFontSize,
        textAlign: 'center',
        paddingTop: singleRowCellPadding,
        fontFamily: 'tahomabd',
      },
      sectionTitle: {
        backgroundColor: '#D9D9D9',
        borderRight: '1pt solid black',
        borderBottom: '1pt solid black',
        borderLeft: '1pt solid black',
        height: dataRowHeight,
      },
      sectionTitleText: {
        fontSize: regFontSize,
        paddingTop: singleRowCellPadding,
        fontFamily: 'tahomabd',
        paddingLeft: 3,
      },
      dataRow: {
        flexDirection: 'row',
        borderRight: '1pt solid black',
        borderLeft: '1pt solid black',
        height: dataRowHeight,
      },
      dataNameCell: {
        width: leftColumnCellWidth,
        borderRight: '1pt solid black',
        height: dataRowHeight,
      },
      dataNameText: {
        paddingLeft: 3,
        fontSize: regFontSize,
        fontFamily: 'tahoma',
        paddingTop: singleRowCellPadding,
      },
      dataStdText: {
        fontSize: regFontSize,
        fontFamily: 'tahoma',
        paddingTop: singleRowCellPadding,
        textAlign: 'center',
      },
      bottomBorder: {
        borderBottom: '1pt solid black',
      },
      rightBorder: {
        borderRight: '1pt solid black',
      },
      footer: {
        flexDirection: 'row',
        position: 'absolute',
        bottom: 15,
        left: 0,
        right: 0,
        paddingLeft: 36,
      },
      footerLeftColumn: {
        width: 179.67,
        fontSize: regFontSize,
        paddingTop: 13,
      },
      footerCenterColumn: {
        textAlign: 'center',
        width: 179.67,
        flexDirection: 'column',
        fontSize: regFontSize,
        paddingTop: 16,
      },
      footerRightColumn: {
        textAlign: 'right',
        paddingLeft: 64,
      },
      footerSmallText: {
        fontSize: footerFontSize,
      },
      footerLargerText: {
        fontsize: regFontSize,
      },
      logo: {
        height: 32.14,
        width: 125,
      },
      uppercase: {
        textTransform: 'uppercase',
      },
      evenRow: {
        backgroundColor: '#ECECEC',
      },
      noData: {
        backgroundColor: '#d9d9d9',
      },
      fakeView: {
        width: 200,
        height: 200,
        border: '1pt solid black',
      },
    });

    // if this pos is not By Position, the page breaks need to change
    let byPos;
    let startPage;

    // if (queryData[0].surveycode.startsWith('E_') && (posCode === 9014 || posCode === 9016)) {
    //   byPos = false;
    //   startPage = 3;
    // } else
    if (
      (!queryData[0].surveydataview.includes('By Position') &&
        !queryData[0].surveydataview.includes('International') &&
        !queryData[0].surveycode.includes('F_')) ||
      (queryData[0].surveycode.includes('F_') && posCode >= 400000)
    ) {
      byPos = false;
      startPage = 2;
    } else {
      byPos = true;
      startPage = 1;
    }

    // The library has difficulty with handling too few cuts and small descriptions
    // on non-By Position cuts. This adds enough content to the description, such
    // that the library will recognize it is more than one page
    let newLines = '';
    if (byPos === false && Object.keys(cuts).length <= 3) {
      for (
        let z = 0;
        z < (contentHeight - posDescHeight) / 15.5555 / 1.5;
        z++
      ) {
        newLines += '\n';
      }
    }

    return (
      <Page wrap style={styles.body} size='LETTER'>
        {/* WATERMARK IF DEMO */}
        {window.location.pathname === '/demo/query/' ? (
          <View fixed>
            <Text style={styles.watermark} fixed>
              SAMPLE
            </Text>
          </View>
        ) : null}

        {/* POSITION TITLE */}
        <View fixed>
          <Text style={styles.posTitle} fixed>
            Position {posCode} - {posName}
          </Text>
        </View>

        {/* POSITION DESCRIPTION */}
        <View>
          <Text style={byPos ? styles.posDesc : styles.posDescFullPage}>
            {posDesc}
            {newLines}
          </Text>
        </View>
        {/* 

        {/* START ON SECOND PAGE IF NOT BY POS
                <View break={!byPos ? true : false}/> */}
        <View break={!byPos ? true : false} />

        {/* HEADER FOR EACH PAGE */}
        <View
          break={!byPos ? true : false}
          fixed
          render={({ pageNumber }) =>
            pageNumber >= startPage && (
              <React.Fragment>
                {/* TDC HEADER */}
                <Text style={styles.sectionHeading}>
                  TOTAL DIRECT COMPENSATION -{' '}
                  {hourly ? '(in Hourly Rates)' : this.getCurrency(country)}
                </Text>

                {/* TABLE HEADERS */}
                <View style={styles.doubleRow} fixed>
                  {/* LEFT SECTION */}
                  <View style={styles.doubleRowCell} fixed>
                    <Text style={styles.centerTextDoubleRowHeader} fixed>
                      TOTAL DIRECT COMPENSATION
                    </Text>
                  </View>

                  {/* RIGHT SECTION */}
                  <View style={styles.headerStackedRows} fixed>
                    {/* TOP ROW */}
                    <View style={styles.singleRowInDoubleTop} fixed>
                      {/* INCUMBENT SECTION */}
                      <View style={styles.incTitleCell} fixed>
                        <Text style={styles.centerTextSingleRowHeader} fixed>
                          INCUMBENT
                        </Text>
                      </View>

                      {/* COMPANY SECTION */}
                      <View style={styles.coTitleCell} fixed>
                        <Text style={styles.centerTextSingleRowHeader} fixed>
                          COMPANY
                        </Text>
                      </View>
                    </View>

                    {/* BOTTOM ROW */}
                    <View style={styles.singleRowInDoubleBottom} fixed>
                      {this.renderPercentiles(percentiles, styles)}
                    </View>
                  </View>
                </View>
              </React.Fragment>
            )
          }
        />

        {/* RENDER CUT SECTIONS */}
        {this.renderCutSections(
          queryData,
          percentiles,
          hourly,
          cuts,
          styles,
          currentCut,
          incCol,
          msoSales,
          byPos
        )}

        {/* FOOTER */}
        <View style={styles.footer} fixed>
          <View style={styles.footerLeftColumn} fixed>
            <Text style={styles.uppercase} fixed>
              DATE EFFECTIVE: {dataEffective}
            </Text>
            <Text style={[styles.uppercase, styles.footerSmallText]} fixed>
              &copy; The Croner Company
            </Text>
            <Text style={[styles.uppercase, styles.footerSmallText]} fixed>
              UNAUTHORIZED REPRODUCTION OR DISTRIBUTION PROHIBITED
            </Text>
          </View>
          <View style={styles.footerCenterColumn} fixed>
            <Text style={styles.uppercase} fixed>
              {surveyName}
            </Text>
            <Text
              style={styles.uppercase}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
          </View>
          <View style={styles.footerRightColumn} fixed>
            <Image style={styles.logo} src={logo} fixed />
          </View>
        </View>
      </Page>
    );
  }
}
